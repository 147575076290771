var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-contain px-5"
  }, [_c('div', {
    staticClass: "font-bold text-3xl"
  }, [_vm._v("Documents")]), _vm.listDocument.length > 0 ? _c('div', {
    staticClass: "flex justify-between mt-10 mb-20"
  }, [_c('div', {
    staticClass: "grid gap-5 grid-layout"
  }, _vm._l(_vm.listDocument, function (data, idx) {
    return _c('div', {
      key: idx,
      staticClass: "card bg-white p-4 rounded-lg cursor-pointer relative flex flex-col justify-between gap-3",
      on: {
        "click": function click($event) {
          return _vm.clickTable(data.name);
        }
      }
    }, [_c('div', {
      staticClass: "font-semibold text-base"
    }, [_vm._v(_vm._s(data.name))]), _c('div', {
      staticClass: "text-xs font-medium text-neutral-300 ellipsis overflow-hidden mdmax:h-auto mdmax:overflow-auto",
      attrs: {
        "title": data.description
      }
    }, [_vm._v(" " + _vm._s(data.description ? data.description : '') + " ")]), _c('p', {
      staticClass: "text-xs text-neutral-500"
    }, [_c('strong', [_vm._v(_vm._s(data.count))]), _vm._v(" File ")]), _c('p', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.notifIsActive(data),
        expression: "notifIsActive(data)"
      }],
      staticClass: "bg-red-400 w-6 h-6 text-base font-semibold text-center text-white rounded-full -top-3 -right-3 absolute"
    }, [_vm._v(_vm._s(data.count))])]);
  }), 0)]) : _c('div', {
    staticClass: "mt-10 mb-20 h-64 flex justify-center items-center"
  }, [_c('p', {
    staticClass: "font-bold text-2xl"
  }, [_vm._v("Document is Empty")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }