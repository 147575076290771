<template>
  <div class="min-h-contain px-5">
    <div class="font-bold text-3xl">Documents</div>
    <div class="flex justify-between mt-10 mb-20" v-if="listDocument.length > 0">
      <div class="grid gap-5 grid-layout">
        <div class="card bg-white p-4 rounded-lg cursor-pointer relative flex flex-col justify-between gap-3" v-for="(data, idx) in listDocument" :key="idx" @click="clickTable(data.name)">
          <div class="font-semibold text-base">{{ data.name }}</div>
          <div class="text-xs font-medium text-neutral-300 ellipsis overflow-hidden mdmax:h-auto mdmax:overflow-auto" :title="data.description">
            {{ data.description ? data.description : '' }}
          </div>
          <p class="text-xs text-neutral-500">
            <strong>{{ data.count }}</strong> File
          </p>
          <p v-show="notifIsActive(data)" class="bg-red-400 w-6 h-6 text-base font-semibold text-center text-white rounded-full -top-3 -right-3 absolute">{{ data.count }}</p>
        </div>
      </div>
      <!-- <div class="w-325">
        <div class="card bg-white rounded-lg p-4 gap-5 mr-6">
          <div class="text-lg leading-10">Description</div>
          <div v-for="(data, idx) in listDocument" :key="idx" class="mt-6">
            <div class="text-base font-bold">{{ data.name ? data.name : '-' }}</div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="mt-10 mb-20 h-64 flex justify-center items-center" v-else>
      <p class="font-bold text-2xl">Document is Empty</p>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      listDocument: []
    }
  },
  computed: {
    isMessage() {
      const data = JSON.parse(localStorage.getItem('talent'))
      if (data) {
        return data
      }
      return []
    }
  },
  methods: {
    ...mapActions('client', ['GET_DATA_DOCUMENT']),
    async getTypeDocument() {
      this.listDocument = await this.GET_DATA_DOCUMENT()
    },
    clickTable(val) {
      this.$router.push({ path: '/document-table', query: { is: val } })
    },
    notifIsActive(data) {
      if (data) if (data.status === 'NEED_APPROVAL' && (data.name.includes('NDA') || data.name.includes('MOU'))) return true
    }
  },
  mounted() {
    this.getTypeDocument()
  }
}
</script>
<style lang="scss" scoped>
.grid-layout {
  grid-template-columns: repeat(4, minmax(100px, 1fr));
}
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.25;
  height: 32px;
  word-break: break-all;
}
</style>
